import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { getEarningDetails } from "actions";
import { DetailHeader, PaymentDetailsTable } from "components";
import { DETAILS_TABLE_HEADER } from "constants/payments";

export const PaymentDetails = () => {
  const data = useSelector((state) => state.payments.earningDetailsList);
  const loading = useSelector((state) => state.payments.loading);

  const dispatch = useDispatch();

  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    const params = {
      start_date: state.startDate,
      end_date: state.endDate,
    };
    if (id) dispatch(getEarningDetails({ id, params }));
  }, []);

  return (
    <div className="payment_details_page">
      <DetailHeader
        name={state?.name}
        startDate={state?.startDate}
        endDate={state?.endDate}
      />
      {loading ? (
        <div className="loading_field">
          <ClipLoader size={50} />
        </div>
      ) : (
        <PaymentDetailsTable
          data={data}
          headerData={DETAILS_TABLE_HEADER}
          startDate={state?.startDate}
          endDate={state?.endDate}
          name={state?.name}
        />
      )}
    </div>
  );
};
