import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "features/auth";
import headerReducer from "features/headerSlice";
import sidebarReducer from "features/sidebarSlice";
import mainReducer from "features/mainSlice";
import footerReducer from "features/footerSlice";
import pageTitleReducer from "features/pageTitleSlice";
import vimeoSettingsReducer from "features/vimeoSettingsSlice";
import testimonialsReducer from "features/testimonialsSlice";
import paymentsReducer from "features/paymnetsSlice";

export const store = configureStore({
  reducer: {
    auth: adminReducer,
    sidebar: sidebarReducer,
    header: headerReducer,
    main: mainReducer,
    footer: footerReducer,
    page: pageTitleReducer,
    vimeoSettings: vimeoSettingsReducer,
    testimonials: testimonialsReducer,
    payments: paymentsReducer,
  },
});
