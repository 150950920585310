import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import clsx from "clsx";

import { TextField } from "@mui/material";

import { setHeaderSearchHover } from "features/headerSlice";
import { SEARCH_KEY } from "constants/general";

import searchIcon from "assets/images/search.svg";

const HeaderSearch = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const searchParam = query.get(SEARCH_KEY);

  const { headerSearchHover } = useSelector((state) => state.header);

  const updateSearch = useCallback(
    (e) => {
      e.preventDefault();

      query.set("search", search);

      navigate(`?${query.toString()}`);
    },
    [query.toString(), search]
  );

  const toggleHeaderSearchHover = () => {
    dispatch(setHeaderSearchHover(!headerSearchHover));
  };

  useEffect(() => {
    if (searchParam) {
      const decodedSearchParam = decodeURIComponent(searchParam);
      setSearch(decodedSearchParam);
    }
  }, [searchParam]);

  return (
    <form onSubmit={updateSearch} className="header-search-wrapper">
      <div
        className={clsx("search-wrapper", {
          "is-active": headerSearchHover,
        })}
      >
        <label className="icon-wrapper" htmlFor="header-search-input">
          <img 
            src={searchIcon}
            alt="Search"
            className="search-icon"
          />
        </label>
        <TextField
          onFocus={toggleHeaderSearchHover}
          onBlur={toggleHeaderSearchHover}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          id="header-search-input"
          name="header-search-input"
          type="search"
          placeholder="Search terms..."
          variant="outlined"
        />
      </div>
    </form>
  );
};

export default HeaderSearch;
