import testimonials_1 from "assets/images/testimonials/testimonials1.png";
import testimonials_2 from "assets/images/testimonials/testimonials2.png";

export const LESS = "less";
export const SEE_MORE = "see more";

export const TESTIMONIALS = [
  {
    id: 1,
    name: "Kyle Shewchuk",
    title: "C21 Millenium, Top Producer",
    image: testimonials_1,
    comment: "I have been working with Property Vision for 6 months now and I'm very impressed with all their services, production, customization, branding, & expertise. They have been a big factor in why my listing have been generating lots of interest, traffic, showings, and offers on all my listings! George has been a vital part of my videos & photography and I would like to say a BIG thank you to him.",
    stars: 5,
  },
  {
    id: 2,
    name: "Sarah Dixon",
    title: "Century 21 Heritage Group Ltd.",
    image: testimonials_2,
    comment: "Not only were they on time and completely professional but their final product was outstanding and you can’t ask for anything more than that. This was my first time using their services but it will certainly not be the last.",
    stars: 1,
  },
  {
    id: 3,
    name: "Kyle Shewchuk",
    title: "C21 Millenium, Top Producer",
    image: testimonials_1,
    comment: "I have been working with Property Vision for 6 months now and I'm very impressed with all their services, production, customization, branding, & expertise. They have been a big factor in why my listing have been generating lots of interest, traffic, showings, and offers on all my listings! George has been a vital part of my videos & photography and I would like to say a BIG thank you to him.",
    stars: 3,
  },
  {
    id: 4,
    name: "Sarah Dixon",
    title: "Century 21 Heritage Group Ltd.",
    image: testimonials_2,
    comment: "Not only were they on time and completely professional but their final product was outstanding and you can’t ask for anything more than that. This was my first time using their services but it will certainly not be the last.",
    stars: 5,
  },
  {
    id: 5,
    name: "Kyle Shewchuk",
    title: "C21 Millenium, Top Producer",
    image: testimonials_1,
    comment: "I have been working with Property Vision for 6 months now and I'm very impressed with all their services, production, customization, branding, & expertise. They have been a big factor in why my listing have been generating lots of interest, traffic, showings, and offers on all my listings! George has been a vital part of my videos & photography and I would like to say a BIG thank you to him.",
    stars: 2,
  }
]