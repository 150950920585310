import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Label } from "../components";

import { getDateWithFormat } from "helpers/momentHelper";
import { PAYMENTS } from "constants/payments";
import { formatNumber } from "helpers/tourDataHelper";

import arrowLeft from "assets/images/payments/leftArrow.svg";
import Logo from "assets/images/sidebarHeader/logo.svg";

export const DetailHeader = ({ name, startDate, endDate }) => {
  const total = useSelector((state) => state.payments.earningDetailsTotal);
  const photographer = useSelector(
    (state) => state.payments.earningPhotographerData
  );

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const startDateFormat = getDateWithFormat(startDate, "MMM DD/YY");
  const endDateFormat = getDateWithFormat(endDate, "MMM DD/YY");

  return (
    <div className="details_header">
      <div className="header_left_side">
        <div className="left_side_top">
          <img
            src={arrowLeft}
            alt="Back"
            className="back_icon"
            onClick={handleBack}
          />
          <div className="date_user_name">
            <div className="date_section">{`${startDateFormat} - ${endDateFormat}`}</div>
            <div className="line_section"></div>
            <div className="name_section">{photographer?.name}</div>
          </div>
        </div>
        <div className="left_side_bottom">
          <img
            src={photographer?.image ? photographer?.image : Logo}
            alt="Avatar"
            className="avatar"
          />
          <p className="user_name">{photographer?.name}</p>
        </div>
      </div>
      <div className="header_right_side">
        <Label
          status={PAYMENTS.paid}
          title={PAYMENTS.total}
          total={`$${formatNumber(total)}`}
          customClass="details_label"
        />
      </div>
    </div>
  );
};
