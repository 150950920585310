import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NotesForm } from "./NotesForm";

import { PAYMENTS } from "constants/payments";
import { getNotes } from "actions";
import { getDateWithFormat } from "helpers/momentHelper";

export const Notes = () => {
  const data = useSelector((state) => state.payments.tourDetails);
  const notes = useSelector((state) => state.payments.notes);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.earning_id) dispatch(getNotes({ id: data?.earning_id }));
  }, [data?.earning_id]);

  return (
    <div className="right_side">
      <p className="notes_title">{PAYMENTS.notes}</p>
      <div className="messages_section">
        <div className="messages">
          {notes.map((message) => (
            <div className="message_element" key={message.id}>
              <div className="date_field">
                <div className="date_line"></div>
                <p className="date">
                  {getDateWithFormat(message?.created_at, "DD.MM.YY")}
                </p>
                <div className="date_line"></div>
              </div>
              <div className="message_field">
                <p className="author">{message?.sender}</p>
                <p className="message">{message?.body}</p>
              </div>
            </div>
          ))}
        </div>
        <NotesForm />
      </div>
    </div>
  );
};
