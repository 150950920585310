import { CONSTANTS, HELPER_TEXT } from "constants/general";
import * as Yup from "yup";

export const testimonialSchema = [
  {
    id: "name",
    name: "name",
    label: CONSTANTS.NAME,
    type: "text",
    placeholder: CONSTANTS.ENTER_NAME,
    backend_field: "name",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    helperText: HELPER_TEXT,
  },
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    helperText: HELPER_TEXT,
  },
  {
    id: "comment",
    name: "comment",
    label: CONSTANTS.COMMENT,
    type: "text",
    placeholder: CONSTANTS.ENTER_COMMENT,
    backend_field: "comment",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "stars",
    name: "stars",
    label: CONSTANTS.STARS,
    type: "text",
    placeholder: CONSTANTS.ENTER_STARS,
    backend_field: "stars",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "image",
    name: "image",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.ENTER_IMAGE,
    backend_field: "image",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
