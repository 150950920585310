import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

import { setSidebarToggleMobile } from "features/sidebarSlice";
import { Collapse } from "@mui/material";
import { Menu } from "menu";

import { ChevronRightTwoTone } from "@mui/icons-material";

const SidebarMenu = () => {
  const [menuOpen, setMenuOpen] = useState(null);
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const mobileToggle = () => {
    dispatch(setSidebarToggleMobile(false));
    setMenuOpen(null);
  };

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <ul>
            {Menu.map((el, index) => (
              <li key={el.id}>
                {el.hasOwnProperty("menu") ? (
                  <>
                    <a
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenuOpen((st) => (st === index ? null : index));
                      }}
                      className={clsx({
                        active:
                          menuOpen === index || pathname.includes(el.path),
                      })}
                    >
                      <span className="sidebar-icon">{el.icon}</span>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                        className="sidebar-item-label"
                      >
                        {el.name}
                      </span>
                      <span
                        className={`sidebar-icon-indicator ${
                          menuOpen === index && "sidebar-icon-indicator-rotate"
                        }`}
                      >
                        <ChevronRightTwoTone />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {el.menu.map((e) => (
                          <li key={e.id}>
                            <NavLink
                              end
                              onClick={() => setSidebarToggleMobile(false)}
                              to={e.path}
                              className={clsx({
                                active: pathname.includes(e.path),
                              })}
                            >
                              {e.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </>
                ) : (
                  <>
                    <NavLink
                      to={el.path}
                      end
                      onClick={mobileToggle}
                      className={clsx({ active: pathname.includes(el.path) })}
                    >
                      <span className="sidebar-icon">{el.icon}</span>
                      <span className="sidebar-item-label">{el.name}</span>
                    </NavLink>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default SidebarMenu;
