import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { CONSTANTS } from "constants/general";
import {
  StyledInputAdornment,
  StyledInputLabel,
  StyledTextField,
} from "./styled";
import { PAYMENTS } from "constants/payments";
import { changeCustomAdjustment } from "actions";

export const CustomAdjustments = () => {
  const data = useSelector((state) => state.payments.tourDetails);
  const loading = useSelector((state) => state.payments.adjustmentLoading);

  const dispatch = useDispatch();

  const initialValues = {
    amount: "",
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required(CONSTANTS.REQUIRED),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        changeCustomAdjustment({
          id: data?.earning_id,
          data: values,
          tourId: data?.id,
        })
      );
    },
  });

  useEffect(() => {
    if (data?.custom_adjustment) {
      formik.setFieldValue("amount", data?.custom_adjustment);
    }
  }, [data?.custom_adjustment]);

  return (
    <form onSubmit={formik.handleSubmit} className="adjustments_form">
      <div className="value_field">
        <StyledInputLabel htmlFor="amount">{PAYMENTS.value}</StyledInputLabel>
        <StyledTextField
          id="amount"
          type="text"
          fullWidth
          value={formik.values.amount}
          onChange={formik.handleChange}
          variant="outlined"
          error={formik.errors.amount && formik.touched.amount}
          helperText={
            formik.touched.amount &&
            formik.errors.amount &&
            formik.errors.amount
          }
          name="amount"
          size="small"
          endAdornment={
            <StyledInputAdornment position="end">$</StyledInputAdornment>
          }
        />
      </div>
      <button className="save_button">
        {loading ? <ClipLoader size={10} /> : CONSTANTS.SAVE}
      </button>
    </form>
  );
};
