export const PATHNAME = {
  edit: "/edit",
  create: "create",
  vimeoSettings: "/vimeo-settings",
  testimonials: "/testimonials",
  appointments: "/appointments",
  payments: "/payments",
};

export const PATHS_WITH_SEARCH = [PATHNAME.payments, PATHNAME.appointments];
