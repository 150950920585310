import { createSlice } from "@reduxjs/toolkit";
import { TESTIMONIALS } from "constants/testimonials";

const initialState = {
    data: TESTIMONIALS,
    editData: {},
};

export const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    setEditTestimonialData: (state, { payload }) => {
      state.editData = payload;
    }
  },
});

export const { setEditTestimonialData } = testimonialsSlice.actions;

export default testimonialsSlice.reducer;
