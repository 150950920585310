import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { setSidebarToggleMobile } from "features/sidebarSlice";
import SidebarHeader from "layoutComponents/SidebarHeader";
import SidebarMenu from "layoutComponents/SidebarMenu";
import { logout } from "features/auth";
import { CONSTANTS } from "constants/general";

import logoutIcon from "assets/images/sidebar-menu/logout.svg";

const Sidebar = () => {
  const { sidebarStyle, sidebarShadow, sidebarToggleMobile } = useSelector(
    (state) => state.sidebar
  );

  const dispatch = useDispatch();

  const toggleSidebarMobile = () => {
    dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };

  const handleScroll = () => {
    document.body.style.overflow = "auto";
  };

  const handleDisableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const authLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div
        className={clsx("app-sidebar", sidebarStyle, {
          "app-sidebar--shadow": sidebarShadow,
        })}
        onMouseMove={handleDisableScroll}
        onMouseLeave={handleScroll}
      >
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
        <div className="logout_field" onClick={authLogout}>
          <img src={logoutIcon} alt="Logout" className="logout_icon" />
          <p className="logout_text">{CONSTANTS.LOG_OUT}</p>
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx("app-sidebar-overlay", {
          "is-active": sidebarToggleMobile,
        })}
      />
    </>
  );
};

export default Sidebar;
