import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "450px",
  }
}));

export const BootstrapDialogActions = styled(DialogActions)(() => ({
  border: "none !important",
  padding: "8px 21px 19px 16px !important",
}));

export const BootstrapDialogContent = styled(DialogContent)(() => ({
  border: "none !important",
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  border: "none !important",
  textTransform: "capitalize",
  width: "fit-content",
}))

export const ConfirmButton = styled(Button)(() => ({
  border: "1px solid #FFDB11",
  padding: "5px 15px",
  fontSize: "13px",
  fontWeight: 400,
  color: "#1A1A1A !important",

  ":hover": {
    backgroundColor: "#FFDB11",
  }
}));

export const RefuseButton = styled(Button)(() => ({
  backgroundColor: "#FFDB11",
  border: "1px solid #FFDB11",
  padding: "5px 15px",
  fontSize: "13px",
  fontWeight: 400,
  color: "#1A1A1A !important",

  ":hover": {
    backgroundColor: "#1A1A1A !important",
    border: "1px solid #1A1A1A",
    color: "#FFDB11 !important",
  }
}))