import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authApi } from "service/authApi";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { RESPONSE_STATUSES } from "service/constants";
import { LOCAL } from "local/localizations";
import { usersApi } from "service/usersApi";

export const login = createAsyncThunk(
  "/users/login",
  async ({ email, password, rem }) => {
    const res = await authApi
      .login({
        email,
        password,
        rem,
      })
      .catch(({ response }) => {
        if (response.status === RESPONSE_STATUSES.unauthorized) {
          Notify(NOTIFY.type.error, LOCAL.INVALID_LOGIN_OR_PASSWORD);
        } else if (response.status === RESPONSE_STATUSES.noPermission) {
          Notify(NOTIFY.type.error, LOCAL.USER_BLOCKED);
        } else if (response.status === RESPONSE_STATUSES.serverErr) {
          Notify(NOTIFY.type.error, LOCAL.SOMETHING_WANT_WRONG);
        }
      });
    return res.data;
  }
);

export const logout = createAsyncThunk("/users/logout", async () => {
  const res = await authApi.logout();
  localStorage.removeItem("access_token");
  sessionStorage.removeItem("access_token");
  return res.data;
});

export const getUser = createAsyncThunk(
  "getUser",
  async (_, { rejectWithValue }) => {
    try {
      const res = await usersApi.getUsers();
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  user: {
    email: "",
    password: "",
  },
  status: null,
  isAuth:
    !!localStorage.getItem("access_token") ||
    !!sessionStorage.getItem("access_token"),
  userData: {},
  userLoading: false,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.status = "solved";
        state.isAuth = true;
      })
      .addCase(login.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(logout.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = [];
        state.status = "solved";
        state.isAuth = false;
      })
      .addCase(logout.rejected, (state) => {
        state.status = "rejected";
      });
    builder
      .addCase(getUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.userData = payload?.data;
        state.userLoading = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.userLoading = false;
      });
  },
});
export const selectAdmin = (state) => state.user;

export const { adminLogin } = adminSlice.actions;

export default adminSlice.reducer;
