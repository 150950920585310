export const getHoursFromSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${!!hours ? `${hours}h` : ""} ${!!minutes ? `${minutes}m` : ""}`;
};

export const getKmFromMeters = (distance) => {
  return distance ? (distance / 1000).toFixed(2) : 0;
};

export const formatNumber = (num) => {
  return num % 1 === 0 ? num : num?.toFixed(2);
};
