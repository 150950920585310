import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import calendarIcon from "assets/images/payments/calendar.svg";
import { getDateWithFormat } from "helpers/momentHelper";

export const CustomDatePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const shownStart = getDateWithFormat(startDate);
  const shownEnd = getDateWithFormat(endDate);

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        selectsRange
        showFullMonthYearPicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={new Date()}
        customInput={
          <button className="calendar_button">
            <img src={calendarIcon} alt="Calendar" className="calendar_icon" />
            <div className="shown_date">{`${shownStart} - ${shownEnd}`}</div>
          </button>
        }
      />
    </div>
  );
};
