import { Routes, Route, Navigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Login from "pages/login";
import LeftSidebar from "LeftSidebar";

import { ChangePassword, Payments, PaymentDetails, TourDetails } from "pages";
import { useEffect } from "react";
import { getUser } from "features/auth";

const PageRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(getUser());
    }
  }, [isAuth]);

  return (
    <>
      {!isAuth ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </Routes>
      ) : (
        <LeftSidebar>
          <Routes>
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/payments/:id" element={<PaymentDetails />} />
            <Route
              path="/payments/:id/tour-details/:tourId"
              element={<TourDetails />}
            />
            {/* Hide Project related unused pages */}
            {/* <Route path="/vimeo-settings" element={<VimeoSettings />} />
            <Route path="/testimonials" element={<Testimonials />} /> */}

            <Route path="/*" element={<Navigate to="/payments" replace />} />
          </Routes>
        </LeftSidebar>
      )}
    </>
  );
};

export default PageRoutes;
