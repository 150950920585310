import { createSlice } from "@reduxjs/toolkit";
import {
  getPayments,
  getEarningDetails,
  getTourDetails,
  changeCustomAdjustment,
  getNotes,
  storeNote,
} from "actions";

const initialState = {
  loading: false,
  photographers: [],
  earningDetailsList: [],
  earningPhotographerData: {},
  earningDetailsTotal: 0,
  toursCount: 0,
  paidAmount: 0,
  payouts: 0,
  tourDetails: {},
  adjustmentLoading: false,
  notes: [],
  notesLoading: false,
  notesStoreLoading: false,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPayments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.photographers = payload?.data?.photographers;
        state.toursCount = payload?.data?.tours_count;
        state.paidAmount = payload?.data?.paid_amount;
        state.payouts = payload?.data?.payouts;
      })
      .addCase(getPayments.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getEarningDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEarningDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.earningDetailsList = payload?.data?.earnings;
        state.earningPhotographerData = payload?.data?.photographer;
        state.earningDetailsTotal = payload?.data?.total;
      })
      .addCase(getEarningDetails.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getTourDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTourDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tourDetails = payload?.data;
      })
      .addCase(getTourDetails.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(changeCustomAdjustment.pending, (state) => {
        state.adjustmentLoading = true;
      })
      .addCase(changeCustomAdjustment.fulfilled, (state, { payload }) => {
        state.adjustmentLoading = false;
      })
      .addCase(changeCustomAdjustment.rejected, (state) => {
        state.adjustmentLoading = false;
      });
    builder
      .addCase(getNotes.pending, (state) => {
        state.notesLoading = true;
      })
      .addCase(getNotes.fulfilled, (state, { payload }) => {
        state.notes = payload?.data;
        state.notesLoading = false;
      })
      .addCase(getNotes.rejected, (state) => {
        state.notesLoading = false;
      });
    builder
      .addCase(storeNote.pending, (state) => {
        state.notesStoreLoading = true;
      })
      .addCase(storeNote.fulfilled, (state, { payload }) => {
        state.notesStoreLoading = false;
      })
      .addCase(storeNote.rejected, (state) => {
        state.notesStoreLoading = false;
      });
  },
});

export default paymentsSlice.reducer;
