import {
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  styled,
} from "@mui/material";

export const StyledInputLabel = styled(InputLabel)({
  color: "#202327",
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: "600",
});

export const StyledInputAdornment = styled(InputAdornment)({
  ".MuiTypography-root": {
    color: "#202327",
    fontSize: "13px",
    lineHeight: "13px",
  },
});

export const StyledTextField = styled(OutlinedInput)({
  backgroundColor: "white",
  borderRadius: "6px",

  ".MuiInputBase-input": {
    fontSize: "14px",
    fontFamily: "initFontRegular",
    height: "32px",
    boxSizing: "border-box",
  },

  ".MuiFormHelperText-root": {
    position: "absolute",
    top: "45px",
    lineHeight: "normal",
    fontSize: "10px",
  },

  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #D1D1D6",
    borderRadius: "6px",
    outline: "none",
  },

  ".Mui-focused": {
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D1D1D6",
    },
  },

  ".Mui-error": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f",
    },
  },

  ".MuiInputBase-root": {
    paddingLeft: "0",
    fontFamily: "initFontRegular",
  },

  ".MuiFormLabel-root": {
    transform: "translate(14px, 13px)",
    fontFamily: "initFontRegular",

    "&.Mui-focused": {
      transform: "translate(14px, -9px) scale(0.75)",
      fontFamily: "initFontRegular",
    },
  },

  ".MuiFormLabel-filled": {
    transform: "translate(14px, -9px) scale(0.75)",
    fontFamily: "initFontRegular",
  },

  "&:placeholder": {
    color: "#74767E",
    fontFamily: "initFontRegular",
  },
});

export const StyledTextAreaField = styled(TextField)({
  backgroundColor: "white",
  borderRadius: "6px",

  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: "6px",
    borderColor: "#D1D1D6",
  },
});
