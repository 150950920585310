export const Status = ({ status, isSuccess = true }) => {
  return (
    <div
      className={`status_label ${isSuccess ? "success_label" : "unpaid_label"}`}
    >
      <div
        className={`label_point ${
          isSuccess ? "success_point" : "unpaid_point"
        }`}
      ></div>
      <p
        className={`status_name ${isSuccess ? "success_name" : "unpaid_name"}`}
      >
        {status}
      </p>
    </div>
  );
};
