import { CONSTANTS } from "constants/general";
import { PAGE_NAME_OBJ } from "constants/pageName";

const collectFormData = (values, pageName) => {
  const formData = new FormData();

  for (let value in values) {
    if (Array.isArray(values[value])) {
      if (
        value === CONSTANTS.IMAGES_FIELD ||
        pageName === PAGE_NAME_OBJ.portfolio ||
        pageName === PAGE_NAME_OBJ.blog
      ) {
        const val = values[value];

        val.forEach((elem) => {
          formData.append(value, elem);
        });
      } else {
        const val = JSON.stringify(values[value]);
        formData.append(value, val);
      }
    } else {
      formData.append(value, values[value]);
    }
  }

  return formData;
};

export const submitData = (
  values,
  dispatch,
  checkCropperMode,
  showImage,
  editContent,
  isFormData,
  apiCall,
  setIsEdit,
  pageName
) => {
  if (values?.services?.[0] === CONSTANTS.OTHER_SERVICE) {
    values.services = [];
  }
  checkCropperMode(values, showImage, editContent);

  if (isFormData) {
    const formData = collectFormData(values, pageName);
    dispatch(apiCall(formData));
  } else {
    dispatch(apiCall(values));
  }

  if (setIsEdit) setIsEdit(false);
};

export const submitForm = ({
  values,
  dispatch,
  checkCropperMode = () => {},
  showImage,
  editContent,
  isFormData,
  apiCall = () => {},
  setIsEdit = null,
  pageName = "",
}) => {
  checkCropperMode(values, showImage, editContent);

  if (isFormData) {
    const formData = collectFormData(values, pageName);
    apiCall(formData);
  } else {
    apiCall(values);
  }

  if (setIsEdit) setIsEdit(false);
};
