export const TourInfo = ({ icon, title, info, isPhone }) => {
  return (
    <div className="tour_info">
      <div className="top_section">
        <img src={icon} className="info_icon" alt="Info" />
        <p className="title">{title}</p>
      </div>
      <div className="bottom_section">
        {isPhone ? (
          <a href={`tel:${info}`} className="info">
            {info}
          </a>
        ) : (
          <p className="info">{info}</p>
        )}
      </div>
    </div>
  );
};
