import instance from "./baseRequest";

export const paymentsApi = {
  getPayments: async (params) =>
    await instance.get("/photographers/admin/earnings", { params }),
  markAsPaid: async (body, id) =>
    await instance.put(
      `/photographers/admin/earnings/mark-as-paid/${id}`,
      body
    ),
  getEarningDetails: async (id, params) =>
    await instance.get(`/photographers/admin/earnings/${id}`, { params }),
  getTourDetails: async (id) =>
    await instance.get(`/photographers/admin/tours/${id}`),
  changeCustomAdjustment: async (id, body) =>
    await instance.put(
      `/photographers/admin/earnings/custom-adjustment/${id}`,
      body
    ),
  getNotes: async (id) =>
    await instance.get(`photographers/earnings/${id}/notes`),
  storeNote: async (body) =>
    await instance.post(`/photographers/earnings/notes`, body),
  discardEarning: async (id) =>
    await instance.post(`/photographers/admin/earnings/discard/${id}`),
};
