import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

import { getPayments } from "actions";
import { PaymentHeader, PaymentsTable } from "components";
import { TABLE_HEADER } from "constants/payments";

export const Payments = () => {
  const dispatch = useDispatch();

  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);

  const [startDate, setStartDate] = useState(fifteenDaysAgo);
  const [endDate, setEndDate] = useState(new Date());

  const photographers = useSelector((state) => state.payments.photographers);
  const loading = useSelector((state) => state.payments.loading);

  useEffect(() => {
    const params = { start_date: startDate, end_date: endDate };
    if (startDate && endDate) dispatch(getPayments({ params }));
  }, [startDate, endDate]);

  return (
    <div className="payments_page">
      <PaymentHeader
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      {loading ? (
        <div className="loading_field">
          <ClipLoader size={50} />
        </div>
      ) : (
        <PaymentsTable
          data={photographers}
          headerData={TABLE_HEADER}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </div>
  );
};
