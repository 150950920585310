import { PAYMENTS } from "constants/payments";

export const MarkAsPaid = ({ disabled, handleAction }) => {
  return (
    <button
      className="mark_as_paid_btn"
      disabled={disabled}
      onClick={handleAction}
    >
      {PAYMENTS.mark_as_paid}
    </button>
  );
};
