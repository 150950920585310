import { useSelector } from "react-redux";

import { Label } from "../components";

import { PAYMENTS } from "constants/payments";
import { CustomDatePicker } from "components";
import { formatNumber } from "helpers/tourDataHelper";

import appointmentsIcon from "assets/images/payments/appointments.svg";
import payoutsIcon from "assets/images/payments/payouts.svg";
import walletIcon from "assets/images/payments/wallet.svg";

export const PaymentHeader = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const toursCount = useSelector((state) => state.payments.toursCount);
  const paidAmount = useSelector((state) => state.payments.paidAmount);
  const payouts = useSelector((state) => state.payments.payouts);

  return (
    <div className="payments_header">
      <div className="page_header">
        <h1 className="payment_title">{PAYMENTS.title}</h1>
        <CustomDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <div className="labels_field">
        <Label
          icon={appointmentsIcon}
          title={PAYMENTS.appointments}
          total={toursCount}
        />
        <Label
          icon={walletIcon}
          title={PAYMENTS.paid}
          total={`$${paidAmount}`}
        />
        <Label
          icon={payoutsIcon}
          title={PAYMENTS.payouts}
          total={`$${formatNumber(payouts)}`}
        />
      </div>
    </div>
  );
};
