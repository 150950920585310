import { Status } from "./Status";

export const Label = ({ icon, title, status, total, customClass = "" }) => {
  return (
    <div className={`label_field ${customClass}`}>
      <div className="label_info">
        {icon && <img src={icon} alt="Label" className="label_icon" />}
        <p className="label_title">{title}</p>
        {status && <Status status={status} />}
      </div>
      <div className="total_field">
        <p className="total">{total}</p>
      </div>
    </div>
  );
};
