import serviceIcon from "assets/images/payments/serviceIcon.svg";

export const PAYMENTS = {
  title: "Payment",
  appointments: "Appointments",
  paid: "Paid",
  payouts: "Payouts",
  mark_as_paid: "Mark as Paid",
  total: "Total",
  user_name: "Guy Hawkins",
  phone_number: "(406) 555-0120",
  date_time: "Date and Time",
  date: "16.06.24, 04:00AM",
  driving_distance: "Driving Distance",
  driving_distance_time: "5h, 80km",
  overview: "Overview",
  total: "Total",
  services_rendered: "Services Rendered",
  travel_fees: "Travel Fees",
  custom_adjustments: "Custom Adjustments ",
  services_and_packages: "Services and Packages ",
  customize: "Customize",
  additional_services: "Additional Services",
  value: "Value",
  notes: "NOTES",
  write_notes: "Write Note",
  discard: "Discard",
  disputed: "DISPUTED",
};

export const TABLE_HEADER = [
  {
    id: 1,
    name: "Photographer",
  },
  {
    id: 2,
    name: "Total",
  },
  {
    id: 3,
    name: "Status",
  },
  {
    id: 4,
    name: "Actions",
  },
];

export const DETAILS_TABLE_HEADER = [
  {
    id: 1,
    name: "Date",
  },
  {
    id: 2,
    name: "Address",
  },
  {
    id: 3,
    name: "Km",
  },
  {
    id: 4,
    name: "Travel Fee",
  },
  {
    id: 5,
    name: "Property Size",
  },
  {
    id: 6,
    name: "Services",
  },
  {
    id: 7,
    name: "Custom Amount",
  },
  {
    id: 8,
    name: "Service Rendered ",
  },
  {
    id: 9,
    name: "Total Pay",
  },
  {
    id: 10,
    name: "Action",
  },
];
