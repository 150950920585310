import { CONSTANTS, HELPER_TEXT } from "constants/general";
import * as Yup from "yup";

export const vimeoSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    helperText: HELPER_TEXT,
  },
  {
    id: "vimeo_client_id",
    name: "vimeo_client_id",
    label: CONSTANTS.VIMEO_CLIENT_ID,
    type: "text",
    placeholder: CONSTANTS.ENTER_VIMEO_CLIENT_ID,
    backend_field: "vimeo_client_id",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "vimeo_client_secret",
    name: "vimeo_client_secret",
    label: CONSTANTS.VIMEO_CLIENT_SECRET,
    type: "text",
    placeholder: CONSTANTS.ENTER_VIMEO_CLIENT_SECRET,
    backend_field: "vimeo_client_secret",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "vimeo_access_token",
    name: "vimeo_access_token",
    label: CONSTANTS.VIMEO_ACCESS_TOKEN,
    type: "text",
    placeholder: CONSTANTS.ENTER_VIMEO_ACCESS_TOKEN,
    backend_field: "vimeo_access_token",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
