import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [
      {
        id: 1,
        title: "First account",
        vimeo_client_id: "7dc82f0b1f17ed892698dd49eacd8cf45ec302d5",
        vimeo_client_secret:
    "vHmCfmeOUXKlf6hpT27+1WvYqOao1biCSh5SllTtXat4sKd2J/c2QLgn44+6BCeIaCioTEyJdmLMOTavaJu6Z6MPKhVYvTYJt8o1Qw/W9jNHfezi2r1Q2dCiS2fZgzO+",
        vimeo_access_token: "0c4b54d753810c2a5e390cb6e8eb4089",
      },
      {
        id: 2,
        title: "Second account",
        vimeo_client_id: "7dc82f0b1f17ed892698dd49eacd8cf45ec302d5",
        vimeo_client_secret:
    "vHmCfmeOUXKlf6hpT27+1WvYqOao1biCSh5SllTtXat4sKd2J/c2QLgn44+6BCeIaCioTEyJdmLMOTavaJu6Z6MPKhVYvTYJt8o1Qw/W9jNHfezi2r1Q2dCiS2fZgzO+",
        vimeo_access_token: "0c4b54d753810c2a5e390cb6e8eb4089",
      },
      {
        id: 3,
        title: "Third account",
        vimeo_client_id: "7dc82f0b1f17ed892698dd49eacd8cf45ec302d5",
        vimeo_client_secret:
    "vHmCfmeOUXKlf6hpT27+1WvYqOao1biCSh5SllTtXat4sKd2J/c2QLgn44+6BCeIaCioTEyJdmLMOTavaJu6Z6MPKhVYvTYJt8o1Qw/W9jNHfezi2r1Q2dCiS2fZgzO+",
        vimeo_access_token: "0c4b54d753810c2a5e390cb6e8eb4089",
      }
    ],
    total: 3,
    editData: {},
};

export const vimeoSettingsSlice = createSlice({
  name: "vimeoSettings",
  initialState,
  reducers: {
    setEditVimeoData: (state, { payload }) => {
      state.editData = payload;
    }
  },
});

export const { setEditVimeoData } = vimeoSettingsSlice.actions;

export default vimeoSettingsSlice.reducer;
