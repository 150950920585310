import { useState } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "@mui/material";

import { CustomAdjustments } from "./CustomAdjustments";
import { Services } from "./Services";
import { Notes } from "./Notes";

import { PAYMENTS } from "constants/payments";
import { formatNumber, getKmFromMeters } from "helpers/tourDataHelper";

import leftIcon from "assets/images/payments/leftArrow.svg";

export const TourDetailsPage = () => {
  const data = useSelector((state) => state.payments.tourDetails);

  const [openCustomAdjustments, setOpenCustomAdjustments] = useState(false);

  const handleCollapseAdjustments = () => {
    setOpenCustomAdjustments(!openCustomAdjustments);
  };

  return (
    <div className="tour_details_page">
      <div className="left_side">
        <div className="tour_overview">
          <p className="overview">{PAYMENTS.overview}</p>
          <div className="summery_section">
            <div className="total_section">
              <p className="total_price">{PAYMENTS.total}</p>
              <p className="total_price">{`$${formatNumber(
                data?.total_earning
              )}`}</p>
            </div>
            <div className="summery_line"></div>
            <div className="summery_elements">
              <div className="element_section">
                <p className="element_text">{PAYMENTS.services_rendered}</p>
                <p className="element_text text_bold">{`$${formatNumber(
                  data?.service_cost
                )}`}</p>
              </div>
              <div className="element_section">
                <p className="element_text">{`${
                  PAYMENTS.travel_fees
                } - ${getKmFromMeters(data?.expected_driving_distance)}km`}</p>
                <p className="element_text text_bold">{`$${formatNumber(
                  data?.travel_cost
                )}`}</p>
              </div>
            </div>
          </div>
          <div className="custom_adjustments">
            <div
              className="adjustments_title_field"
              onClick={handleCollapseAdjustments}
            >
              <p className="adjustments_title">{PAYMENTS.custom_adjustments}</p>
              <img
                src={leftIcon}
                alt="Arrow"
                className={`arrow_icon ${
                  openCustomAdjustments ? "arrow_top" : ""
                }`}
              />
            </div>
            <Collapse in={openCustomAdjustments}>
              <CustomAdjustments />
            </Collapse>
          </div>
        </div>
        <Services />
      </div>
      <Notes />
    </div>
  );
};
