import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useFormik } from "formik";
import * as Yup from "yup";

import { StyledInputLabel, StyledTextAreaField } from "./styled";

import { CONSTANTS } from "constants/general";
import { storeNote } from "actions";
import { PAYMENTS } from "constants/payments";

export const NotesForm = () => {
  const data = useSelector((state) => state.payments.tourDetails);
  const loading = useSelector((state) => state.payments.notesStoreLoading);

  const dispatch = useDispatch();

  const initialValues = {
    body: "",
  };

  const validationSchema = Yup.object().shape({
    body: Yup.string().required(CONSTANTS.REQUIRED),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const sendData = { ...values, earning_id: data?.earning_id };
      dispatch(storeNote({ data: sendData }));
      resetForm();
    },
  });

  return (
    <form className="notes_form" onSubmit={formik.handleSubmit}>
      <div className="note_field">
        <StyledInputLabel htmlFor="body">
          {PAYMENTS.write_notes}
        </StyledInputLabel>
        <StyledTextAreaField
          id="body"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={formik.values.body}
          onChange={formik.handleChange}
          variant="outlined"
          error={formik.errors.body && formik.touched.body}
          helperText={
            formik.touched.body && formik.errors.note && formik.errors.body
          }
          name="body"
          size="small"
        />
      </div>
      <button
        className="save_button"
        disabled={!formik.values.body}
        type="submit"
      >
        {loading ? <ClipLoader size={10} /> : CONSTANTS.SEND}
      </button>
    </form>
  );
};
