import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { MarkAsPaid, Status } from "components";
import { markAsPaidAction } from "actions/payments";

import showIcon from "assets/images/payments/show.svg";
import { formatNumber } from "helpers/tourDataHelper";

export const PaymentsTable = ({ data, headerData, startDate, endDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMarkAsPaid = (id) => {
    dispatch(
      markAsPaidAction({
        id,
        data: { start_date: startDate, end_date: endDate },
      })
    );
  };

  const handleNavigate = (id, name) => {
    navigate(`${id}`, { state: { startDate, endDate, name } });
  };

  return (
    <div className="payment_table">
      <table cellPadding="1" cellSpacing="1" className="table" bgcolor="#fff">
        <thead>
          <tr className="header_row">
            {headerData.map((element) => (
              <th className="header_element" key={element.id}>
                {element.name}
              </th>
            ))}
            <th className="header_element header_show_element"></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((element) => (
            <tr className="table_column" key={element?.id}>
              <td className="table_element">{element?.name}</td>
              <td className="table_element">{`$${formatNumber(
                element?.total_earnings
              )}`}</td>

              <td className="table_element">
                <Status
                  status={element?.is_paid ? "Paid" : "Unpaid"}
                  isSuccess={element?.is_paid}
                />
              </td>
              <td className="table_element">
                <MarkAsPaid
                  disabled={element?.is_paid}
                  handleAction={() => handleMarkAsPaid(element?.id)}
                />
              </td>
              <td className="table_element show_column">
                <img
                  src={showIcon}
                  alt="Show"
                  className="show_icon"
                  onClick={() => handleNavigate(element?.id, element?.name)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
