export const RESPONSE_STATUSES = {
  success: 200,
  unauthorized: 401,
  noPermission: 403,
  notFound: 404,
  unprocessableEntity: 422,
  serverErr: 500,
};

export const FORM_ITEM_NAMES_OBJ = {
  title: "title",
  description: "description",
  subtitle: "subtitle",
  subtitleDescription: "subtitleDescription",
  items: "items",
  icon: "icon",
  image: "image",
  oldPassword: "old_password",
  newPassword: "new_password",
  newPasswordConfirmation: "new_password_confirmation",
  answers: "answers",
  vimeo_client_id: "vimeo_client_id",
  vimeo_client_secret: "vimeo_client_secret",
  vimeo_access_token: "vimeo_access_token",
  name: "name",
  comment: "comment",
  stars: "stars",
};

export const FORM_ITEM_TYPES_OBJ = {
  text: "text",
  textarea: "textarea",
  array: "array",
  file: "file",
  password: "password",
};

export const TEXTFIELD_VARIANTS_OBJ = {
  outlined: "outlined",
};

export const FORM_TEXTAREA_ROWS_COUNT_OBJ = {
  min: 4,
  max: 10,
};

export const BUTTON_NAMES_OBJ = {
  add: "+ Add",
  back: "Back",
};

export const BUTTON_TYPES_OBJ = {
  submit: "submit",
};

export const DIAGNOSTIC_STATUS = {
  complete: "Complete",
  incomplete: "Incomplete",
};

export const USER_STATUS = {
  unblocked: "Unblocked",
  blocked: "Blocked",
  active: "Active",
};
