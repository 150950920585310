export const NOTIFY = {
  type: {
    success: "success",
    error: "error",
  },
  message: {
    add: "Successfully added",
    delete: "Successfully removed",
    update: "Successfully updated",
    wrong: "Something went wrong",
    old_password: "Old password is incorrect",
    mark_paid: "Successfully mark as paid",
    adjustment: "Custom adjustment has been successfully applied.",
    discard: "Successfully discarded",
  },
};

export const getNotificationMessages = (name, key) => {
  return name + " " + NOTIFY.message[key];
};
