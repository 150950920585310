import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useActionHelper } from "hooks/useActionHelper";

import { changePasswordSchema } from "./ChangePasswordSchema";
import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { authApi } from "service/authApi";
import { CONSTANTS } from "constants/general";
import { TextField } from "@mui/material";
import {
  FORM_ITEM_NAMES_OBJ,
  FORM_ITEM_TYPES_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import { Save } from "@mui/icons-material";
import { submitForm } from "helpers/formikDataCollect";
import { useDispatch } from "react-redux";
import { SaveButton } from "components/Button";

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const [changePassword] = useActionHelper({
    createNewItemApiCall: authApi.changePassword,
    customNotify: CONSTANTS.PASSWORD_CHANGE_SUCCESS,
  });

  const [initialValues, validation] = formikValidation(changePasswordSchema);

  const apiCall = changePassword;

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      submitForm({
        values,
        dispatch,
        apiCall,
      });
    },
  });

  return (
    <div className="form_container">
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="field_Container">
          <TextField
            autoFocus
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.oldPassword}
            name={FORM_ITEM_NAMES_OBJ.oldPassword}
            label={CONSTANTS.CURRENT_PASSWORD}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.password}
            value={formik.values[FORM_ITEM_NAMES_OBJ.oldPassword]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.oldPassword,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.oldPassword,
            })}
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.newPassword}
            name={FORM_ITEM_NAMES_OBJ.newPassword}
            label={CONSTANTS.NEW_PASSWORD}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.password}
            value={formik.values[FORM_ITEM_NAMES_OBJ.newPassword]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPassword,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPassword,
            })}
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.newPasswordConfirmation}
            name={FORM_ITEM_NAMES_OBJ.newPasswordConfirmation}
            label={CONSTANTS.CONFIRM_PASSWORD}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.password}
            value={formik.values[FORM_ITEM_NAMES_OBJ.newPasswordConfirmation]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPasswordConfirmation,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.newPasswordConfirmation,
            })}
          />
        </div>
        <SaveButton />
      </form>
    </div>
  );
};
