import { createAsyncThunk } from "@reduxjs/toolkit";

import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { paymentsApi } from "service/paymentsApi";

export const getPayments = createAsyncThunk(
  "getPayments",
  async ({ params }, { rejectWithValue }) => {
    try {
      const { data } = await paymentsApi.getPayments(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markAsPaidAction = createAsyncThunk(
  "markAsPaid",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      await paymentsApi.markAsPaid(data, id);
      dispatch(getPayments({ params: data }));
      Notify(NOTIFY.type.success, NOTIFY.message.mark_paid);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEarningDetails = createAsyncThunk(
  "getEarningDetails",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const { data } = await paymentsApi.getEarningDetails(id, params);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTourDetails = createAsyncThunk(
  "getTourDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await paymentsApi.getTourDetails(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeCustomAdjustment = createAsyncThunk(
  "changeCustomAdjustment",
  async ({ id, data, tourId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await paymentsApi.changeCustomAdjustment(id, data);

      Notify(NOTIFY.type.success, NOTIFY.message.adjustment);
      dispatch(getTourDetails({ id: tourId }));

      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNotes = createAsyncThunk(
  "getNotes",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await paymentsApi.getNotes(id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const storeNote = createAsyncThunk(
  "storeNote",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      const res = await paymentsApi.storeNote(data);

      Notify(NOTIFY.type.success, NOTIFY.message.adjustment);
      dispatch(getNotes({ id: data?.earning_id }));

      return res;
    } catch (error) {
      Notify(NOTIFY.type.error, NOTIFY.message.wrong);

      return rejectWithValue(error);
    }
  }
);

export const discardEarning = createAsyncThunk(
  "discardEarning",
  async ({ id, params, photographerId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await paymentsApi.discardEarning(id);

      Notify(NOTIFY.type.success, NOTIFY.message.discard);
      dispatch(getEarningDetails({ params, id: photographerId }));

      return res;
    } catch (error) {
      Notify(NOTIFY.type.error, NOTIFY.message.wrong);

      return rejectWithValue(error);
    }
  }
);
